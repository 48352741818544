

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";




import logoImg from '../../assets/logo.png'


import { currencyList, separatorNumber, useGlobalFunc } from "../../global/constants.js";



import Chart from 'react-apexcharts'
import Banner from "../../compontents/Banner";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const scrollContainerRef = useRef(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [scrollPosition, setScrollPosition] = useState(0)

    const [currTimeIndex, setCurrTimeIndex] = useState(0)

    const [dataList, setDataList] = useState([])


    function handleResize() {
        setWindowHeight(window.innerHeight)
    }


    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return <div className="relative  px-4 py-10 " >


        <Banner />


        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">訊息通知</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">時間</th>
                            <th className="">標題</th>
                            <th className="">狀態</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-top text-white font-semibold ">
                                <td className="">2023-08-03  16:25</td>
                                <td className="">機器人到期服務通知</td>
                                <td className="">未讀</td>


                            </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>





    </div >
}

export default Home