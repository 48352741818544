import api from './api.jsx'

export default class UserApi {

    static preRegister(data) {
        return api({
            url: '/user/preRegister',
            method: "POST",
            data
        })
    }

    static signIn(data) {
        return api({
            url: '/user/login',
            method: "POST",
            data
        })
    }

    static me() {
        return api({
            url: '/user',
            method: "GET",
        })
    }
   
    static updateMe(data) {
        return api({
            url: '/user',
            method: "PUT",
            data
        })
    }

    static userFunding() {
        return api({
            url: '/user/funding',
            method: "GET",
        })
    }
    static funding() {
        return api({
            url: '/funding',
            method: "GET",
        })
    }

    static ad(data) {
        return api({
            url: `/negotiation/dispatch/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

}