
export const en = {
    "register success": "註冊成功，請等待審核通過。",



    days:"D",
    hours:"H",
    minutes:"M",
    seconds:"S",

    header: {
        artAuction: "Art Auction",
        onlineGallery: "Online Gallery",
        artFocus: "Art Focus",
        customerService: "Customer Service",
        aboutUs: "About Us",


    },

    newsType: {
        all: "全部新聞",
        newsFocus: "焦點新聞",
        internationalNews: "國際新聞",
        artisticCrossing: "藝文跨界",
        artCritique: "藝術評論",
        artIndustry: "藝術產業",
        specialProject: "專題企劃",
        auctionNews: "拍賣消息",
        solicitation: "徵件補助",
    },
    memberCenterType: {
        orderRecord: "出價紀錄",
        registerPlace: "註冊場次",
        successOrder: "得標項目",
        artCert: "藝品憑證",
        applyFunction: "申請功能",
        monthlySystem: "月結收入",
        myFavor: "我的最愛",
        setting: "設定",
        appraisalApply: "鑑價申請",
        commissionedApply: "委託申請",
        transferNFT: "藝品NFT憑證轉移",
        buyBackApply: "回購申請",
        artistRegister: "藝術家申請",

        payRecord: "消費紀錄",


        artManage: "藝術品管理",
        intro: "關於藝術家編輯",
        exhibitionExperience: "展經歷編輯",
        artistInfo: "藝術家資料"
    },
    favoriteType: {
        'artArticle': "藝術新聞",
        'artVideo': "藝文影音",
        'artGathering': "藝術聚點",
        'artist': "藝術家",
        'product': "藝術品"

    },
    artAuction: {
        physicalAuction: "藝術拍賣會",
        onlineAuction: "線上拍賣",
        auctionNews: "拍賣消息",

        auctionService: "拍賣服務",
        inAuctionService: {
            text1: "拍賣會藝品徵件",
            text2: "我們正在徵集藝術品、書畫及古董藏品，以供拍賣會出售。如果您有任何具有收藏價值的藝術品或古董，歡迎提交申請並參加我們的徵件活動。我們將提供專業鑑定和優質展示服務，確保您的藏品得到最佳的市場價值。",
            text3: "委託藝品線上拍賣",
            text4: "我們提供專業的委託線上拍賣服務。只需提交藏品資訊和照片，我們將為您提供客觀的鑑定、優質的展示和行銷方案，讓您的藏品得到最佳的市場價值。我們致力於為您提供最好的服務和體驗，歡迎參與我們的線上拍賣活動。",
            text5: "客服與服務據點",
            text6: "我們提供優質客戶服務和據點，您可以透過電子郵件、電話或傳真等方式進行溝通。我們的客戶服務團隊將竭誠為您提供專業的建議和協助，確保您的需求得到及時滿足，歡迎隨時聯繫我們，我們期待為您服務。",
        },


        auctionIntro: "拍賣簡介",
        auctionIntroBtn1: "藝品拍賣會規則",
        auctionIntroBtn2: "線上拍賣規則",

    },
    onlinegallery: {
        artBtn1: "線上藝廊常見問題",
        artBtn2: "藝術家問答集",
        artBtn3: "作品裱框與維護",
        artBtn4: "會員使用者註冊條款",
        artBtn5: "隱私權政策",
        artBtn6: "免責聲明",

    },
    artFocus: {
        news: "藝術新聞",
        exhibition: "展覽活動",
        place: "藝術聚點",
        video: "藝文影音"
    },
    customerService: {
        page1: "鑑價藏品",
        inPage1: {
            text0: "鑑價藏品",
            text1: "鑑價對象：",
            text2: "我們接受各種收藏品的鑑價，包括但不限於藝術品、古玩、珠寶、 骨董、金幣、郵票等。",
            text3: "鑑價方式：",
            text4: "會員可以透過平台提供的「藏品鑑價」功能提交鑑價申請。我們的專業鑑定團隊將根據收到的藏品資訊進行鑑價。",
            text5: "鑑價費用：",
            text6: "鑑價費用為定額收取，具體收費會在申請者提出申請後經由客服人員告知，申請者需在確認鑑價時支付費用，否則無法進行鑑價。",
            text7: "鑑價報告：",
            text8: "我們會在收到鑑價費用後盡快進行鑑價，報告中會包含藏品的詳細資訊和建議的價格範圍。若需實物鑑定，我們會與會員約定時間和地點進行鑑定。",
            text9: "免責聲明：",
            text10: "我們的鑑價報告僅供參考，不構成任何交易建議或保證。會員應自 行承擔購買、出售等交易風險，我們不對任何交易後果負責。",

            text100: "鑑價藏品流程",
            text101: "會員填寫鑑價申請表，提供藏品的基本資料和圖片，並繳納定額鑑價費用。",
            text102: "我們收到申請後，會安排專員做初步的電話資訊確認，並在確認收到鑑價費用後，交由專業的鑑定師進行審核和評估。",
            text103: "鑑定師會根據藏品的品相、稀有度、市場需求等因素進行評估，並提供建議的鑑價範圍。",
            text104: "我們將鑑定結果記錄在鑑價報告中，並在報告中詳細說明鑑價過程、鑑定標 準和結論，報告會以電子檔案的方式提供給會員。",

            text200: "注意事項",
            text201: "藏品鑑價是基於會員提供的資訊進行評估，並非實際看到藏品本身，因此可能會有一定的誤差，僅供參考。",
            text202: "消費者在申請藏品回購服務時，應提供藏品的真實照片，以便我們對藏品進行檢驗。",
            text203: "如果會員提供的藏品資訊不完整或不正確，可能會導致鑑價報告的誤差，建議提供盡可能詳細的藏品資訊。",
            text204: "平台有權根據鑑價報告的情況決定是否接受會員的藏品上架拍賣，以確保平台的質量和信譽。",

            text301: "請注意，鑑價結果僅供參考，並不代表該藏品實際售價，市場行情變化會影響 藏品的價值。此外，請務必提供真實的藏品資訊和圖片，若發現提供虛假資料 將有可能影響鑑價結果或被取消鑑價資格。",
        },

        page2: "藏品回購",
        inPage2: {
            text0: "藏品回購",
            text1: "回購申請：",
            text2: "消費者可以向我們網站申請回購服務。在申請時，消費者需要提供藏品的詳細信息，包括名稱、編號、購買日期、購買價格等。",
            text3: "回購價格：",
            text4: "我們將根據市場行情、拍賣品的狀況和消費者購買時的價格等因素確定藏品的回購價格。回購價格將由我們向消費者提出並協商確定。",
            text5: "藏品檢驗：",
            text6: "我們將對藏品進行檢驗，以確定其是否符合買回要求。拍賣品必須是在我們網站上購買的，且保存良好，無重大瑕疵。如果藏品不符合買回要求，我們有權拒絕買回。",
            text7: "付款方式：",
            text8: "我們將通過銀行轉賬等方式向消費者支付買回價格。",
            text9: "手續費：",
            text10: "我們將收取一定的手續費，用於管理和處理藏品買回的相關事宜。手續費的金額將在買回價格中扣除。",
            text11: "保留權利：",
            text12: "我們保留根據實際情況對藏品買回規則進行修改和調整的權利。",

            text100: "藏品回購流程",
            text101: "消費者在網站上提交藏品買回申請，提供詳細的藏品信息，包括名稱、編號、購買日期、購買價格等。",
            text102: "網站收到申請後，將對藏品進行檢驗，以確定其是否符合買回要求。拍賣品必須是在網站上購買的，且保存良好，無重大瑕疵。",
            text103: "如果藏品符合買回要求，網站將向消費者提出買回價格並協商確定。買回價格將根據市場行情、藏品的狀況和消費者購買時的價格等因素確定。買回價格一經確定，即為最終價格，消費者不得再次進行議價或其他要求。",
            text104: "如果藏品不符合買回要求，網站有權拒絕買回申請。如果拍賣品在交易過程中出現瑕疵或損壞，網站有權拒絕買回申請或進行價格調整。",
            text105: "消費者必須在指定的時間內寄送藏品，否則買回申請將被視為無效。在藏品送達網站後，網站將進行最終的檢驗，以確定藏品的狀況是否符合買回要求。",
            text106: "網站將在確定藏品符合買回要求後，向消費者支付買回價格。網站將通過銀行轉賬等方式向消費者支付買回價格，手續費的金額將在買回價格中扣除。",

            text200: "注意事項",
            text201: "藏品回購服務僅適用於在我們網站上購買的藏品。如果您是在其他地方購買的藏品，我們無法提供回購服務。",
            text202: "消費者應確保提供的藏品信息準確無誤，包括名稱、編號、購買日期、購買價格等。如果提供的信息有誤，可能會影響到買回價格的確定和藏品的檢驗。",
            text203: "消費者在申請藏品回購服務時，應提供藏品的真實照片，以便我們對藏品進行檢驗。",
            text204: "在藏品回購服務過程中，消費者需要支付一定的手續費，消費者應注意手續費的金額，以便更好地理解買回價格的計算方式。",
            text205: "消費者在將藏品寄送給賣家時，應選擇合適的物流公司，確保藏品能夠安全到達我們的手中。",
            text206: "買回價格一經確定，即為最終價格，消費者不得再次進行議價或其他要求。",
            text207: "如果藏品在交易過程中出現瑕疵或損壞，我們有權拒絕買回申請或進行價格調整。在收到藏品後，我們將對其進行詳細檢查，如果發現藏品的狀況不符合買回要求，我們將與消費者協商處理方式。如果回購申請被拒絕，我們將退回藏品並安排退回買家。",

        },
        page3: "委託申請-拍賣",
        inPage3: {
            text0: "委託申請-拍賣",
            text1: "本平台提供線上拍賣會，讓會員與藝術家能夠在一個固定的時間段裡進行線上拍賣，吸引更多買家參與競拍。",

            text100: "委託申請拍賣流程",
            text101: "您必須是我們的會員才能進行委託線上拍賣。",
            text102: "在後台填寫申請表時，提供拍賣藝術品資訊，包括圖片、描述和起始價格等。",
            text103: "經過我們的審核，確認資料符合規範後，藝術品將會於下一期的線上拍賣中上架。",
            text104: "若藝術品有買家投標並得標後，請在平台規範的時間內將藝術品寄送到平台指定的收件地址，平台會協助出貨給買家。",
            text105: "當您的藝術品成功售出後，我們將扣除一定比例的交易手續費，並於一定時間 內將款項轉入您的帳戶。如果您的藏品未售出，我們將會通知您並安排相關事宜。",
        },
        page4: "委託申請-定價託賣",
        inPage4: {
            text0: "委託申請-定價",
            text1: "本平台提供會員與藝術家委託定價販售的服務，我們將協助展示藝術品，並透過多元化的網路行銷推廣，以確保能夠得到更好的曝光率和更好的銷售表現。",

            text100: "委託定價申請流程",
            text101: "您必須是我們的會員才能進行委託定價拍賣。",
            text102: "在後台填寫申請表時，提供藝術品資訊，包括圖片、描述和欲販售定價等。",
            text103: "藝術品售出後，請在平台規範的時間內將藝術品寄送到平台指定的收件地址，平台會協助出貨給買家。",
            text104: "經過我們的審核，確認資料符合規範後，藝術品將在平台上的線上藝廊中進行販售。",
            text105: "藝術品售出後，請在平台規範的時間內將藝術品寄送到平台指定的收件地址，平台會協助出貨給買家。",
            text106: "當您的藝術品成功售出後，我們將扣除一定比例的交易手續費，並於一定時間內將款項轉入您的帳戶。",

            text200: "定價委託規則",
            text201: "申請流程：",
            text202: "申請者需要在後台填寫申請表格，提交藝術品資訊及售價等相關資 料。平台審核通過後即可上架。",
            text203: "售價：",
            text204: "售價由申請者自行設定，藝術家應詳細考量藏品價值、市場需求及自身需求後進行定價。",
            text205: "手續費：",
            text206: "平台會扣除一定的手續費，具體手續費率請參照平台公告。",
            text207: "其他注意事項：",
            text208: "藝術家需確保藏品信息真實、正確，不得有虛假數據、敘述，否則平台有權將其下架。藝術家不得利用本平台從事任何違反法律法規 的活動，否則平台有權隨時終止與其合作並追究其法律責任。",
        },
        page5: "藝術家申請",
        inPage5: {
            text0: "藝術家申請",
            text1: "我們提供一個方便的功能，讓使用者成為藝術家帳號，並進一步進行拍賣和定價販售藏品。只要您成為我們的會員後，即可申請成為藝術家帳號。經過申請審核後，您就可以在我們的網站上開始展示、拍賣和販售您的藏品。透過這個功能，您可以更加便利地管理您的藏品，並得到更多的曝光機會。",

            text100: "藝術家申請流程",
            text101: "登入會員，點擊申請成為藝術家。",
            text102: "填寫申請表，包括個人基本資料、作品經歷和相關證明文件。",
            text103: "提交申請表後，系統會進行審核。",
            text104: "審核通過後，系統會發送一封確認信到您提供的電子郵件地址。",
            text105: "成功成為藝術家後，您可以在後台上架藏品，並開始進行拍賣或定價販售。",

            text200: "注意事項",
            text201: "申請表中的個人資料和作品資訊必須真實可靠。",
            text202: "藝術家需遵守相關法律法規，保護版權和著作權。",
            text203: "我們會對提交的申請表和相關證明文件進行嚴格審核，如發現虛假信息，我們有權取消申請資格並保留追究責任的權利。",
            text204: "申請流程中如有任何疑問，請隨時聯繫我們的客服。",

        },
        page6: "拍賣會藝品徵件",
        inPage6: {
            text0: "徵件時間",
            text1: "拍品常年徵集中",

            text100: "徵件項目",
            text101: "古董珍玩",
            text102: "藝術家需要在後台填寫申請表格，提交藏品資訊及售價等相關資料。平台審核通過後即可上架。",
            text103: "近現代書畫",
            text104: "我們歡迎來自張大千、吳湖帆、齊白石、胡適、林語堂、梁實秋、李鴻 章、左宗棠、鄭板橋、徐悲鴻及其他知名書畫大家的作品。",
            text105: "古代書畫",
            text106: "包括宋、元、明、清等不同時期的古代書畫作品。",
            text107: "現代與當代藝術",
            text108: "我們歡迎來自蔡國強、艾未未、蕭勤、劉國松、村上隆、奈良美智、草間彌生、Damien Hirst、Jeff Koons、Banksy 等知名藝術家的作品。",

            text200: "如何送件",
            text201: "聯絡客服",
            text202: "在天堯拍賣平台上，您可以提供拍品照片、年代、類別、尺寸、價格及其他補充資料。",
            text203: "專業審核",
            text204: "我們的專家小組會對您的作品進行評估和鑑定審核。如有需要我們會與您聯繫並約定日期進行實物查看。一旦審核通過，您的拍品就可以刊登在拍賣平上。",
            text205: "藝術品拍賣活動",
            text206: "如果您的作品通過審核，請保留拍品至拍賣結束。",

        },

        page7: "客服與服務據點",
        inPage7: {
            text0: "客戶與服務據點",
            text1: "傳真",
            text2: "電話",
        },

        page8: "藝品拍賣競標－即時通知機器人",
        inPage8: {
            text0: "拍賣競標機器人 Line Bot",
            text1: "我們的拍賣競標機器人是一個非常方便的工具，可以幫助您更順利地參與拍賣。透過我們的拍賣競標機器人，您可以隨時掌握拍賣品的最新狀況，例如出價是否被超越、拍賣即將結束等。此外，我們還提供了許多實用的功能，例如出價、查看當期拍賣、查看已出價的拍品、得標通知等，讓您更方便地參與拍賣，並及時得到最新資訊。如果您想要更輕鬆地參與拍賣，歡迎您使用我們的拍賣競標機器人。",

            text100: "功能步驟",
            text101: "打開 LINE 應用程式，點選右上角的「加好友」按鈕。",
            text102: "選擇「QR 碼」選項，並掃描以下QR 碼。",
            text103: "掃描完成後，點選「加入好友」按鈕。",
            text104: "系統將會提示你已成功加入我們的 LINE BOT，你可以開始使用相關功能。",

        },

        cue1: "欲申請鑑價藏品，須先登入帝愛會員",
        login: "會員登入"

    },
    aboutus: {
        page1: "我們的故事",
        page2: "我們的使命",
        page3: "大事記",
        page4: "聯絡我們",

    },
    path: {
        artauction: "藝品拍賣",
        physical: "實體",
        intro: "介紹",
        catolog: "電子圖鑑",
        online: "線上",
        theme: "當前",
        past: "過往",

        artistlist: "藝術家",
        artist: "藝術家",

        cart: "購物車",
        artlist: "藝術品",
        art: "藝術品",


        onlinegallery: "線上藝廊",


        customerservice: "客戶服務",
        aboutus: "關於我們",


        artfocus: "藝術焦點",

        artnews: "藝術新聞",
        exhibition: "展覽活動",
        artplace: "藝術聚點",
        artvideo: "藝文影音",

        FAQ: "線上藝廊常見問題",
        QandA: "藝術家問答集",
        artmaintenance: "作品裱框與維護",
        terms: "服務條款",
        rules:"簡介",



        membercenter: "會員中心",
        artistcenter: "藝術家中心",

        login: "登入",
        register: "註冊",
        "": "首頁",



    }



}