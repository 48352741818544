

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import UserApi from "../../services/UserApi";
//import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../../firebase";

import { useDispatch, useSelector } from "react-redux";

import loginlogoImg from '../../assets/logo.png'
import logintitleImg from '../../assets/google.png'

import pic1Img from '../../assets/pic1.png'

import googleloginImg from '../../assets/google.png'
import fbloginImg from '../../assets/fb.png'



import axios from 'axios'
import { useGlobalFunc } from "../../global/constants";
import Banner from "../../compontents/Banner";

const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()

    const [step, setStep] = useState(0)

    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()



    const [faqs, setFAQ] = useState([


    ]);

    const [account, setAccount] = useState("")
    const [pw, setPw] = useState("")



    const [FAQType, setFAQType] = useState(0);
    const [isFisrt, doneFisrt] = useState(false)

    function changeFAQType(type) {
        setFAQType(type)
    }

    function changeFAQItem(index) {
        console.log(faqs[FAQType][index])
        faqs[FAQType][index].open = !faqs[FAQType][index].open
        console.log(faqs[FAQType][index])
        setFAQ([...faqs])
    }



    async function loginAction() {
        if (account == "" || pw == "") {
            successToast("請輸入帳號和密碼")
            return
        }

        setLoading(true)

        try {
            let res = await UserApi.signIn({ accountId: account, accountPw: pw })
            console.log(res.data)

            localStorage.setItem("userToken", res.data.data.user.loginToken)
            updateMe()
            navigate('/profile')
            //window.location.reload()
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }

        setLoading(false)

    }




    useEffect(() => {

        const token = query.get('token')
        if (token != null) {
            //localStorage.setItem("userAuth",token)
            setStep(100)

        }
    }, []);


    useEffect(() => {

        console.log(userInfo)
        if (userInfo != null) {
            navigate('/dashboard')
        }

    }, [userInfo]);


    return <div className="min-h-screen" >
        <Banner />


        <div className="max-w-[800px] mx-auto rounded-md  w-full  px-4 md:px-8" >
            <img className="w-full object-contain rounded-lg" src={pic1Img} />

        </div>


        <div className="flex w-full  ">
            <div className="m-auto   w-full max-w-[470px] p-7 md:rounded-[20px]">

                <div className="relative py-7">

                    <p className="text-white text-center text-2xl font-bold mb-8">帳號登入</p>

                    <p className="text-white font-bold">帳號</p>

                    <input value={account} onChange={e => setAccount(e.target.value)} type="text" className=" text-lg  w-full mb-6" />


                    <p className="text-white  font-bold">密碼</p>
                    <input placeholder="" value={pw} onChange={e => setPw(e.target.value)} type="password" className="mb-8  relative    w-full  text-lg " />



                    <button onClick={() => loginAction()} className=" font-bold bg-main3  text-white w-full rounded-full py-4 mb-4">登入</button>

                </div>







            </div>





        </div>



    </div>
}

export default Home