import { brandLink, useGlobalFunc } from "../global/constants"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import axios from 'axios'

import closeWtImg from '../assets/closewt.png'
import menuImg from '../assets/menu.png'

import logoImg from '../assets/logo.png'




import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useSelector, useDispatch } from 'react-redux';
import ActionDialog from "./ActionDialog";
import AlertDialog from "./AlertDialog";



function DownWalletDialog(props) {
    const { onClose, selectedValue, open } = props;
    //const theme = useTheme();

    const [checkboxStatus, setCheckboxStatus] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)

    const [count, setCount] = useState(1)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('768'));



    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };






    return (
        <Dialog maxWidth="2000" PaperProps={{ sx: { bgcolor: "transparent" } }} fullScreen={fullScreen} onClose={handleClose} open={open} >
            <div className="w-full h-full" onClick={handleClose}></div>
            <div className="lg:w-[510px] text-white absolute lg:relative bottom-0 left-0 w-full bg-[#1C1E21]  p-[28px] pt-[17px] rounded-t-md">
                <div className="relative py-7">

                    <div className="text-black absolute top-0 right-0 text-base font-medium"  >
                        <img className="w-6 h-6 object-contain cursor-pointer" alt="" onClick={handleClose} />
                    </div>



                    <p className="text-lg lg:text-2xl text-[#FFFFFF] mb-[37px] font-bold text-center ">使用 APP 來設置錢包</p>



                    <div className="px-3 bg-[#FFFFFF] bg-opacity-10 rounded-[10px] pt-[64px] pb-[45px]">



                    </div>
                </div>


            </div>
        </Dialog>
    );
}

const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);
    const userPoint = useSelector(state => state.userPoint);

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { updateMe, setLoading } = useGlobalFunc();

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    const menuData = [
        {
        },
        {
        }
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openLang = Boolean(anchorEl);
    const handleLangClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLangClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        handleLangClose()
        return
        console.log(i18n.language)
        if (i18n.language == 'zh') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh');
        }


    };

    const [openMenu, setOpenMenu] = useState(false);

    const [selProductTypeIndex, setSelProductTypeIndex] = useState(0);



    const [isTransparentMenu, setIsTransparentMenu] = useState(false);

    const routeList = [
        // {
        //     img: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        //         <path d="M4.58333 3.66667C4.58333 3.42355 4.48676 3.19039 4.31485 3.01849C4.14294 2.84658 3.90978 2.75 3.66667 2.75C3.42355 2.75 3.19039 2.84658 3.01849 3.01849C2.84658 3.19039 2.75 3.42355 2.75 3.66667V16.0417C2.75 16.8926 3.08802 17.7086 3.6897 18.3103C4.29138 18.912 5.10743 19.25 5.95833 19.25H18.3333C18.5764 19.25 18.8096 19.1534 18.9815 18.9815C19.1534 18.8096 19.25 18.5764 19.25 18.3333C19.25 18.0902 19.1534 17.8571 18.9815 17.6852C18.8096 17.5132 18.5764 17.4167 18.3333 17.4167H5.95833C5.59366 17.4167 5.24392 17.2718 4.98606 17.0139C4.7282 16.7561 4.58333 16.4063 4.58333 16.0417V3.66667ZM13.75 5.5C13.5069 5.5 13.2737 5.59658 13.1018 5.76849C12.9299 5.94039 12.8333 6.17355 12.8333 6.41667C12.8333 6.65978 12.9299 6.89294 13.1018 7.06485C13.2737 7.23676 13.5069 7.33333 13.75 7.33333H15.6622L12.1458 10.8497L10.5023 9.20608C10.3303 9.03424 10.0972 8.9377 9.85417 8.9377C9.6111 8.9377 9.37798 9.03424 9.20608 9.20608L6.22692 12.1853C6.13937 12.2698 6.06953 12.371 6.02149 12.4828C5.97345 12.5946 5.94816 12.7149 5.9471 12.8366C5.94605 12.9583 5.96924 13.0791 6.01533 13.1917C6.06142 13.3044 6.12949 13.4067 6.21555 13.4928C6.30162 13.5788 6.40397 13.6469 6.51663 13.693C6.62928 13.7391 6.74999 13.7623 6.8717 13.7612C6.99342 13.7602 7.1137 13.7349 7.22554 13.6868C7.33737 13.6388 7.43852 13.569 7.52308 13.4814L9.85417 11.1503L11.4978 12.7939C11.6697 12.9658 11.9028 13.0623 12.1458 13.0623C12.3889 13.0623 12.622 12.9658 12.7939 12.7939L16.9592 8.6295L16.9583 10.5417C16.9583 10.7848 17.0549 11.0179 17.2268 11.1898C17.3987 11.3618 17.6319 11.4583 17.875 11.4583C18.1181 11.4583 18.3513 11.3618 18.5232 11.1898C18.6951 11.0179 18.7917 10.7848 18.7917 10.5417L18.7935 6.41667C18.7935 6.17355 18.6969 5.94039 18.525 5.76849C18.3531 5.59658 18.1199 5.5 17.8768 5.5H13.75Z" fill="#E1E1E1" />
        //     </svg>,
        //     title: '資產總覽',
        //     path: '/'
        // },
        // {
        //     img: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
        //         <path d="M20.1666 15.845V4.78084C20.1666 3.68084 19.2683 2.86501 18.1775 2.95668H18.1225C16.1975 3.12168 13.2733 4.10251 11.6416 5.12918L11.4858 5.23001C11.22 5.39501 10.78 5.39501 10.5141 5.23001L10.285 5.09251C8.65331 4.07501 5.73831 3.10334 3.81331 2.94751C2.72248 2.85584 1.83331 3.68084 1.83331 4.77168V15.845C1.83331 16.725 2.54831 17.55 3.42831 17.66L3.69415 17.6967C5.68331 17.9625 8.75415 18.9708 10.5141 19.9333L10.5508 19.9517C10.7983 20.0892 11.1925 20.0892 11.4308 19.9517C13.1908 18.98 16.2708 17.9625 18.2691 17.6967L18.5716 17.66C19.4516 17.55 20.1666 16.725 20.1666 15.845Z" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M11 5.53247V19.2825" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M7.10419 8.28247H5.04169" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M7.79169 11.0325H5.04169" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //     </svg>,
        //     title: '利息收益表現',
        //     path: "/interest",
        // },
        // {
        //     img: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
        //         <path d="M11 20.6666C16.0627 20.6666 20.1666 16.5627 20.1666 11.5C20.1666 6.43723 16.0627 2.33331 11 2.33331C5.93723 2.33331 1.83331 6.43723 1.83331 11.5C1.83331 12.9666 2.17798 14.3526 2.7894 15.5819C2.95256 15.9082 3.00665 16.2813 2.91223 16.6342L2.36681 18.6747C2.313 18.8766 2.3132 19.0891 2.3674 19.2909C2.4216 19.4926 2.5279 19.6766 2.67563 19.8243C2.82336 19.9721 3.00734 20.0784 3.20911 20.1326C3.41088 20.1868 3.62336 20.187 3.82523 20.1331L5.86573 19.5877C6.21993 19.4986 6.5945 19.5419 6.91898 19.7096C8.18666 20.3409 9.58383 20.6685 11 20.6666Z" stroke="#E1E1E1" stroke-width="1.5" />
        //         <path d="M11 14.5552C12.0129 14.5552 12.8333 13.8714 12.8333 13.0281C12.8333 12.1847 12.0129 11.5 11 11.5C9.98704 11.5 9.16663 10.8161 9.16663 9.9719C9.16663 9.12856 9.98704 8.44473 11 8.44473M11 14.5552C9.98704 14.5552 9.16663 13.8714 9.16663 13.0281M11 14.5552V15.1666M11 8.44473V7.83331M11 8.44473C12.0129 8.44473 12.8333 9.12856 12.8333 9.9719" stroke="#E1E1E1" stroke-width="1.5" stroke-linecap="round" />
        //     </svg>,
        //     title: '出入金紀錄',
        //     path: "/aboutus",
        // },
        // {
        //     img: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        //         <path d="M11 11C13.5313 11 15.5834 8.94795 15.5834 6.41665C15.5834 3.88534 13.5313 1.83331 11 1.83331C8.46872 1.83331 6.41669 3.88534 6.41669 6.41665C6.41669 8.94795 8.46872 11 11 11Z" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M18.8742 20.1667C18.8742 16.6192 15.345 13.75 11 13.75C6.65502 13.75 3.12585 16.6192 3.12585 20.1667" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        //     </svg>,
        //     title: '個人資訊設定',
        //     path: "/contactus",
        // },


    ]

    const loginRouteList = [
        // {
        //     img: KOLpassImg,
        //     title: "KOL 通證",
        //     path: "/ownerworld?tag=0",
        // },

    ]

    const [showSwapLink, setShowSwapLink] = useState(false)

    const [currPath, setCurrPath] = useState()
    const [showStoryMenu, setShowStoryMenu] = useState(false)

    const [showUserInfo, setShowUserInfo] = useState(false)


    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }


    function isCurrentPath(selPath) {
        let path = location.pathname.split("/");
        //console.log(path, selPath)
        if ('/' + path[1] == selPath) {
            return true
        }
        else {
            return false
        }

    }


    function logout() {
        setLoading(true)
        localStorage.removeItem('userToken')
        dispatch({
            type: "UPDATE_USERINFO",
            payload: { userInfo: null }
        });
        navigate('/')
        window.location.reload()
        setLoading(false)
    }

    function navigateToPath(path) {
        navigate(path)
        setOpenMenu(false)

    }

    useEffect(() => {

        // const token = query.get('token')
        //if (token != null && window.location.pathname == '/login') {
        //    localStorage.setItem("userAuth", token)
        // }
        if (localStorage.getItem('userToken')) {
            updateMe()
        }
    }, [])

    useEffect(() => {

        // const token = query.get('token')
        //if (token != null && window.location.pathname == '/login') {
        //    localStorage.setItem("userAuth", token)
        // }


        if (!localStorage.getItem('userToken')) {
            //navigate('/login')
        }
        else {
            //navigate('/profile')
        }

    }, [location.pathname])

    useEffect(() => {
        return
        if (location.pathname == "/" || location.pathname == "/aboutus") {
            setIsTransparentMenu(true)
        }
        else {
            setIsTransparentMenu(false)
        }

    }, [location.pathname])

    return <div className="">
        <div className="relative w-full h-20 md:hidden bg-main1"> {/**手機 */}
            <div className="fixed top-0 left-0 w-full h-20 p-4 flex justify-between items-center ">
                <div className=" w-40 h-10  ">
                    <img onClick={() => navigate('/')} className=" cursor-pointer w-full object-contain" src={logoImg} />
                </div>
                <div className=" w-12 h-12 p-2">
                    <img className=" object-contain w-full  cursor-pointer" onClick={() => setOpenMenu(true)} src={menuImg} />
                </div>
            </div>

        </div>

        <div className={`${openMenu ? "" : "hidden md:block"} w-full md:w-[260px] fixed left-0 top-0 h-full z-20  flex flex-col  bg-main1 px-8 py-6`}>
            <img className="md:hidden ml-auto w-6 mb-4 cursor-pointer" onClick={() => setOpenMenu(false)} src={closeWtImg} />
            <div className=" w-full h-16  mb-14">
                <img onClick={() => navigate('/')} className=" cursor-pointer w-full h-full object-contain" src={logoImg} />
            </div>

            <div className=" w-full   ">
                {
                    routeList.map((i, index) => <button onClick={() => navigateToPath(i.path)} className={`${isCurrentPath(i.path) ? "bg-white text-main1" : "text-white"} w-full flex gap-3 items-center p-0.5 font-bold rounded-full  mb-3`}>
                        <div className="bg-main1 rounded-full p-2">
                            {i.img}
                        </div>
                        <p>{i.title}</p>
                    </button>)
                }
            </div>

            {
                userInfo ?
                    <div>
                        <button onClick={() => navigateToPath('/dashboard')} className={`${isCurrentPath('/dashboard') ? "bg-white text-main1" : "text-white"} w-full flex gap-3 items-center p-0.5 font-bold rounded-full  mb-3`}>
                            <div className="bg-main1 rounded-full p-2">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.58333 19.25C4.07917 19.25 3.64742 19.0703 3.28808 18.711C2.92875 18.3517 2.74939 17.9202 2.75 17.4167V4.58333C2.75 4.07917 2.92967 3.64742 3.289 3.28808C3.64833 2.92875 4.07978 2.74939 4.58333 2.75H10.0833V19.25H4.58333ZM11.9167 19.25V11H19.25V17.4167C19.25 17.9208 19.0703 18.3526 18.711 18.7119C18.3517 19.0713 17.9202 19.2506 17.4167 19.25H11.9167ZM11.9167 9.16667V2.75H17.4167C17.9208 2.75 18.3526 2.92967 18.7119 3.289C19.0713 3.64833 19.2506 4.07978 19.25 4.58333V9.16667H11.9167Z" fill="#F2F2F2" />
                                </svg>
                            </div>
                            <p>總覽</p>
                        </button>
                        <button onClick={() => navigateToPath('/profile')} className={`${isCurrentPath('/profile') ? "bg-white text-main1" : "text-white"} w-full flex gap-3 items-center p-0.5 font-bold rounded-full  mb-3`}>
                            <div className="bg-main1 rounded-full p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11 11C13.5313 11 15.5834 8.94795 15.5834 6.41665C15.5834 3.88534 13.5313 1.83331 11 1.83331C8.46872 1.83331 6.41669 3.88534 6.41669 6.41665C6.41669 8.94795 8.46872 11 11 11Z" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.8742 20.1667C18.8742 16.6192 15.345 13.75 11 13.75C6.65502 13.75 3.12585 16.6192 3.12585 20.1667" stroke="#F6F6F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <p>我的</p>
                        </button>
                        <button onClick={() => logout()} className=" font-bold w-full rounded-lg border border-solid border-white p-2 text-white">
                            登出
                        </button>

                    </div>
                    :
                    <button onClick={() => navigate('/login')} className=" font-bold w-full rounded-lg border border-solid border-white p-2 text-white">
                        登入
                    </button>
            }


        </div>

    </div>

}
export default Header