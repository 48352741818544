import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navigate = useNavigate()



  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('userToken')) {
      navigate('/login')
    }

  }, [pathname]);

  return null;
}