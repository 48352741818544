

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";




import logoImg from '../../assets/logo.png'


import { separatorNumber, useGlobalFunc, time2date2 } from "../../global/constants.js";



import Chart from 'react-apexcharts'
import UserApi from "../../services/UserApi";
import { useSelector } from "react-redux";




const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()

    const scrollContainerRef = useRef(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [scrollPosition, setScrollPosition] = useState(0)



    let pie1State = {

        series: [85.17, 12.71, 2.62, 1.41, 0.07],
        options: {

            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['USDT', 'USD', 'EUR', 'JPY', '閒置資金'],


            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'left',
                labels: {
                    colors: '#FFFFFF' // 修改标签文字颜色
                }
            },
            colors: ['#3450AE', '#F6C35F', '#8ED1AD', '#91C1F3', '#545E8E']
        },
    }

    let pie2State = {

        series: [78, 22],
        options: {

            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['已借出', '未借出',],


            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'left',
                labels: {
                    colors: '#FFFFFF' // 修改标签文字颜色
                }
            },
            colors: ['#3450AE', '#FEA34E']
        },
    }



    const [showInsertKey, setShowInsertKey] = useState(false)
    const [keyId, setKeyId] = useState('')
    const [keySecret, setKeySecret] = useState('')

    const [userFunding, setUserFunding] = useState(null)



    async function sendKey() {
        if (keyId == "" || keySecret == "") {
            successToast("請輸入Key ID和 Key Secret")
            return
        }

        setLoading(true)

        try {
            let res = await UserApi.updateMe({ keyId: keyId, keySecret: keySecret })
            console.log(res.data)
            updateMe()
            getUserFunding()

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        finally {
            setLoading(false)
            setShowInsertKey(false)
        }


    }

    async function deleteKey() {
        setLoading(true)

        try {
            let res = await UserApi.updateMe({ keyId: null, keySecret: null })
            console.log(res.data)
            updateMe()

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }

        setLoading(false)

    }

    async function getUserFunding() {

        try {
            let res = await UserApi.userFunding()
            console.log(res.data)
            if (!res.data.data) {
                setUserFunding(res.data.data)
            }

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }

    }



    useEffect(() => {
        getUserFunding()

    }, []);

    return <div className="relative  px-4 py-10 " >

        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">用戶管理</p>
            <div className=" flex items-center gap-2 w-full bg-main3 bg-opacity-40 text-white font-bold px-4 py-4 ">
                <div>用戶ID： 123456</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.8001 2.3999C10.1636 2.3999 9.55318 2.65276 9.10309 3.10285C8.653 3.55293 8.40015 4.16338 8.40015 4.7999V14.3999C8.40015 15.0364 8.653 15.6469 9.10309 16.097C9.55318 16.547 10.1636 16.7999 10.8001 16.7999H18.0001C18.6367 16.7999 19.2471 16.547 19.6972 16.097C20.1473 15.6469 20.4001 15.0364 20.4001 14.3999V7.6967C20.4 7.06023 20.1471 6.44988 19.6969 5.9999L16.8001 3.1031C16.3502 2.65298 15.7398 2.40004 15.1033 2.3999H10.8001Z" fill="#F2F2F2" />
                    <path d="M3.59985 9.59971C3.59985 8.96319 3.85271 8.35274 4.3028 7.90265C4.75288 7.45256 5.36333 7.19971 5.99985 7.19971V19.1997H15.5999C15.5999 19.8362 15.347 20.4467 14.8969 20.8968C14.4468 21.3468 13.8364 21.5997 13.1999 21.5997H5.99985C5.36333 21.5997 4.75288 21.3468 4.3028 20.8968C3.85271 20.4467 3.59985 19.8362 3.59985 19.1997V9.59971Z" fill="#F2F2F2" />
                </svg>
            </div>

            <div className=" w-full  p-4 md:p-8" >
                <div className=" flex justify-between items-center gap-2 w-full  text-white  ">
                    <div>
                        <p className="text-base font-bold text-white mb-2">訂閱方案到期管理</p>
                        <p className="text-sm text-white ">您的 Rolling X 機器人將於到期日後停止運作。</p>
                    </div>
                    <button className="w-28 rounded-full text-sm py-3 bg-main3">訂閱管理</button>
                </div>
            </div>

            <div className=" w-full  p-4 md:p-8" >
                <div className=" flex justify-between items-center gap-2 w-full  text-white  ">
                    <div>
                        <p className="text-base font-bold text-white mb-2">高低利率方案管理</p>
                        <p className="text-sm text-white ">根據您的資金運用喜好，自由選擇高低利率方案。</p>
                    </div>
                    <button className="w-28 rounded-full text-sm py-3 bg-main3">利率管理</button>
                </div>
            </div>

            <div className=" w-full  p-4 md:p-8" >
                <div className=" flex justify-between items-center gap-2 w-full  text-white  ">
                    <div>
                        <p className="text-base font-bold text-white mb-2">電子郵件</p>
                        <p className="text-sm text-white ">使用您的電子郵件保護您的帳戶與交易。</p>
                    </div>
                    <button className="w-28 rounded-full text-sm py-3 bg-main3">編輯管理</button>
                </div>
            </div>

            <div className=" w-full  p-4 md:p-8" >
                <div className=" flex justify-between items-center gap-2 w-full  text-white  ">
                    <div>
                        <p className="text-base font-bold text-white mb-2">登入密碼</p>
                        <p className="text-sm text-white ">用以登入您的帳戶的登入密碼。</p>
                    </div>
                    <button className="w-28 rounded-full text-sm py-3 bg-main3">編輯管理</button>
                </div>
            </div>

            <div className=" w-full  p-4 md:p-8" >
                <div className=" flex justify-between items-center gap-2 w-full  text-white  ">
                    <div>
                        <p className="text-base font-bold text-white mb-2">通知設定</p>
                        <p className="text-sm text-white ">停用後，您將不會收到相關的站內收件匣通知。</p>
                    </div>
                    <button className="w-28 rounded-full text-sm py-3 bg-main3">停用通知</button>
                </div>
            </div>
            
        </div>




        {
            userInfo && <div>
                <div className="bg-main2  rounded-md mb-4 p-4 md:p-8">
                    <div className="sm:flex justify-between items-end">
                        <div>
                            <div className="text-white font-bold mb-6">
                                <p className="text-2xl mb-4">{userInfo.accountId}</p>
                                <p className="text-red-400 mb-4">會員到期時間: {time2date2(userInfo.availableExpirationDate)}</p>
                                <div className="mb-4">
                                    <p className=" break-all mb-2 text-sm">Key ID: {(userInfo.keyId || '尚未輸入')}</p>
                                    <p className=" break-all  text-sm">Key Secret: {(userInfo.keySecret || '尚未輸入')}</p>
                                </div>

                                {
                                    (userInfo.keyId == null || userInfo.keySecret == null) ?
                                        <button className="bg-sec1 font-bold  text-main1 rounded-md px-2 py-1" onClick={() => setShowInsertKey(true)}> 新增Key</button>
                                        :
                                        <button className="border-sec1 border-solid border text-sec1 font-bold   rounded-md px-2 py-1" onClick={() => deleteKey()}> 刪除Key</button>
                                }
                            </div>

                            {
                                showInsertKey === true &&
                                <div className=" rounded-xl bg-main1 p-4 ">
                                    <p className="text-white text-center mb-4">新增Key</p>
                                    <div className="text-white mb-2">
                                        Key ID:
                                        <input value={keyId} onChange={(e) => setKeyId(e.target.value)} className="w-full" placeholder="KeyID" />
                                    </div>
                                    <div className="text-white mb-6">
                                        Key Secret:
                                        <input value={keySecret} onChange={(e) => setKeySecret(e.target.value)} className="w-full" placeholder="KeyID" />
                                    </div>
                                    <button className="bg-sec1 font-bold  text-main1 rounded-md px-2 py-1" onClick={() => sendKey()}> 確定送出</button>

                                </div>
                            }



                        </div>

                    </div>

                </div>
                <div className="hidden grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                        <p className="text-base font-bold text-white mb-4">當日年化報酬估算</p>
                        <div className="flex justify-between items-end">
                            <p className="text-xl font-bold text-white">20 %</p>
                            <p className="text-white text-sm">APR</p>
                        </div>
                    </div>

                    <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                        <p className="text-base font-bold text-white mb-4">當日收益估算</p>
                        <div className="flex justify-between items-end">
                            <p className="text-xl font-bold text-green1">+{separatorNumber('2111000.11')}</p>
                            <p className="text-white text-sm">USDT</p>
                        </div>
                    </div>

                    <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                        <p className="text-base font-bold text-white mb-4">總利息收入</p>
                        <div className="flex justify-between items-end">
                            <p className="text-xl font-bold text-green1">+{separatorNumber('2111000.11')}</p>
                            <p className="text-white text-sm">USDT</p>
                        </div>
                    </div>

                </div>

                <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 hidden">
                    {/* <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                        <p className="text-base font-bold text-white mb-4">已借出資金</p>
                        <div className="w-full max-w-[380px] ">
                            <Chart options={pie1State.options} series={pie1State.series} type="pie" width={'100%'} />
                        </div>

                    </div> */}

                    <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                        <p className="text-base font-bold text-white mb-4">資金分佈</p>
                        <div className="w-full max-w-[380px]">
                            <Chart options={pie2State.options} series={pie2State.series} type="pie" width={'100%'} />
                        </div>

                    </div>
                </div>

                <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4 mt-4" >
                    <p className="text-base font-bold text-white mb-4">各貨幣狀態</p>
                    <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                        <table className="w-full text-sm" >
                            <thead className="">

                                <tr className="  text-white bg-main1 font-semibold rounded-xl">
                                    <th className="">幣種</th>
                                    <th className="">總數量</th>
                                    <th className="">可用</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    userFunding && userFunding.assets.map((i, index) => <tr className="align-top text-white font-semibold ">
                                        <td className=" flex gap-2 items-center mr-5">
                                            <p>{i.currency}</p>
                                        </td>
                                        <td className="">{separatorNumber(i.balance)} </td>
                                        <td className="">{separatorNumber(i.availableBalance)} </td>
                                    </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4 mt-4" >
                    <p className="text-base font-bold text-white mb-4">已借出</p>
                    <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                        <table className="w-full text-sm" >
                            <thead className="">

                                <tr className="  text-white bg-main1 font-semibold rounded-xl">
                                    <th className="">幣種</th>
                                    <th className="">總數量</th>
                                    <th className="">年利率</th>
                                    <th className="">期數</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    userFunding && userFunding.lents.map((i, index) => {
                                        return i.orders.map((order, index) =>
                                            <tr className="align-top text-white font-semibold ">
                                                <td className=" flex gap-2 items-center mr-5">
                                                    <p>{i.currency}</p>
                                                </td>
                                                <td className="">{separatorNumber(order.amount)} </td>
                                                <td className="">{separatorNumber(order.rate)}%</td>
                                                <td className="">{separatorNumber(order.period)}</td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4 mt-4" >
                    <p className="text-base font-bold text-white mb-4">已掛單</p>
                    <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                        <table className="w-full text-sm" >
                            <thead className="">

                                <tr className="  text-white bg-main1 font-semibold rounded-xl">
                                    <th className="">幣種</th>
                                    <th className="">總數量</th>
                                    <th className="">年利率</th>
                                    <th className="">期數</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    userFunding && userFunding.toBeLents.map((i, index) => {
                                        return i.orders.map((order, index) =>
                                            <tr className="align-top text-white font-semibold ">
                                                <td className=" flex gap-2 items-center mr-5">
                                                    <p>{i.currency}</p>
                                                </td>
                                                <td className="">{separatorNumber(order.amount)} </td>
                                                <td className="">{separatorNumber(order.rate)}%</td>
                                                <td className="">{separatorNumber(order.period)}</td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        }




    </div >
}

export default Home