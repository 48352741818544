import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";


import titlelogoImg from '../assets/titlelogo.png'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';


const Banner = () => {
  const location = useLocation()
  const { t } = useTranslation();

  const loading = useSelector(state => state.loading);

  return <div className="flex flex-col justify-center items-center w-full py-8 sm:py-16  mb-4" >
    <img className="w-full max-w-[300px] object-contain mb-8" src={titlelogoImg} />

    <p className="text-sm font-medium text-main0 text-center">借助自動化的力量，無需手動出借資金，即可使您的被動收入達最大化</p>

  </div>
}
export default Banner