import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'

import Loading from './compontents/Loading'

import Login from "./pages/Login";

import Home from "./pages/Home";

import EventPre from "./pages/EventPre";
import Message from "./pages/Message";

import Interest from "./pages/Interest";
import SelectCurrencyInterest from "./pages/SelectCurrencyInterest"

import Profile from "./pages/Profile";

import Register from "./pages/Register";



import { Provider } from "react-redux";
import { store } from "./model/store.js";


import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";

import bgImg from '../src/assets/bg.jpg'

import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import './firebase.js';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter >
      <div className='relative w-full h-full bg-[#1D2032] text-main3'>
        <ScrollToTop />
        <Loading />

        <ToastContainer closeButton={false} icon={false} position="top-center" toastClassName="!rounded !overflow-hidden !bg-main1 !bg-opacity-90  !backdrop-blur-sm mb-1 !border !border-solid !border-sec1 !text-white" className=" !p-2  " bodyClassName={() => "flex items-center text-white"} />


        <div className="flex flex-col md:flex-row min-h-screen">
          <div className='w-full md:w-[260px] flex-none relative'>
            <Header />
          </div>

          <div className='w-full 1max-w-[1280px] relative '>
          <img className=' absolute left-0 top-0 w-full h-full object-cover object-top ' src={bgImg} />
            <div className='w-full relative'>
              <Routes>
                {/* <Route path="/event/pre" element={<EventPre />}></Route> */}
                <Route path="/" element={<Login />}></Route>
                <Route path="/dashboard" element={<Home />}></Route>
                <Route path="/interest" element={<Interest />}></Route>
                <Route path="/interest/:id" element={<SelectCurrencyInterest />}></Route>

                <Route path="/message" element={<Message />}></Route>

                <Route path="/profile" element={<Profile />}></Route>


                <Route path="/login" element={<Login />}></Route>

                {/* <Route path='*' element={<Navigate to='/event/pre' replace />} /> */}

                {/* <Route path='*' element={<Navigate to='/' replace />} /> */}
              </Routes>
            </div>
            {/* <Footer /> */}
          </div>
        </div>

      </div>

    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
