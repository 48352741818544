

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";




import logoImg from '../../assets/logo.png'
import titlelogoImg from '../../assets/titlelogo.png'
import pic1Img from '../../assets/pic1.png'

import { currencyList, separatorNumber, useGlobalFunc } from "../../global/constants.js";



import Chart from 'react-apexcharts'
import { LinearProgress } from "@mui/material";
import Banner from "../../compontents/Banner";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const scrollContainerRef = useRef(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [scrollPosition, setScrollPosition] = useState(0)

    const [currTimeIndex, setCurrTimeIndex] = useState(0)

    const data = [
        {
            name: 'STOCK ABC',
            data: [
                8107.85, 8128.0, 8122.9, 8165.5, 8340.7, 8423.7, 8423.5, 8514.3,
                8481.85, 8487.7, 8506.9, 8626.2, 8668.95, 8602.3, 8607.55,
                8512.9, 8496.25, 8600.65, 8881.1, 9340.85,
            ],
        },
    ]

    let pie1State = {

        series: [85.17, 12.71, 2.62, 1.41, 0.07],
        options: {

            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['USDT', 'USD', 'EUR', 'JPY', '閒置資金'],


            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'left',
                labels: {
                    colors: '#FFFFFF' // 修改标签文字颜色
                }
            },
            colors: ['#3450AE', '#F6C35F', '#8ED1AD', '#91C1F3', '#545E8E']
        },
    }

    let pie2State = {

        series: [78, 22],
        options: {

            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['已借出', '未借出',],


            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'left',
                labels: {
                    colors: '#FFFFFF' // 修改标签文字颜色
                }
            },
            colors: ['#3450AE', '#FEA34E']
        },
    }






    const handleScroll = () => {
        //console.log(windowHeight)
        const newScrollPosition = scrollContainerRef.current.scrollTop;
        console.log(newScrollPosition)
        setScrollPosition(newScrollPosition);

    };

    function isCurrentPage(page) {
        //console.log(page)
        //console.log(windowHeight * (page - 1), scrollPosition, windowHeight * (page + 2))
        if (scrollPosition > windowHeight * (page - 1) && scrollPosition < windowHeight * (page + 2)) {
            return true
        }
        else {
            return false
        }

    }

    function countOpacity(page) {
        if (scrollPosition > windowHeight * (page) - (windowHeight * 0.1) && scrollPosition < windowHeight * (page) + (windowHeight * 0.1)) {
            return 1
        }
        else {
            return 0
        }


    }

    function handleResize() {
        setWindowHeight(window.innerHeight)
    }


    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return <div className="relative  px-4 py-10  " >


        {/* <div className="bg-main2  rounded-md mb-4 p-4 md:p-8">
            <div className="sm:flex justify-between items-end">
                <div>
                    <div className="flex gap-2 items-center mb-2">
                        <p className="text-base font-bold text-white ">總資產</p>

                    </div>
                    <p className="text-xl font-bold text-white mb-2">${separatorNumber('123123112')}</p>
                    <p className="text-xs font-bold text-green1 mb-4">+${separatorNumber('6618524')} <span className="text-gray-400">今日</span></p>
                </div>
                <div>
                    <div className="mb-2 flex justify-between bg-[#343D59] text-white rounded-md p-1 gap-1">
                        {
                            ['日', '週', '月', '季', '半年'].map((i, index) => <button onClick={() => setCurrTimeIndex(index)} className={`${index == currTimeIndex ? "bg-[#515D86]" : ""}  w-12 text-sm text-center py-0.5 rounded-md`}>
                                {i}
                            </button>)
                        }

                    </div>
                    <p className="text-xs text-right font-bold text-green1 mb-4">每小時日利率 : 14%  APR</p>
                </div>
            </div>
            <Chart
                options={{
                    grid: {
                        show: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                    },
                    colors: ["#5BF77C"],
                    fill: {
                        type: 'gradient',
                        gradient: {
                            type: "vertical",
                            shadeIntensity: 0,
                            opacityFrom: 0.8,
                            opacityTo: 0.2,
                            stops: [0, 80, 100],
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 3,
                    },
                    labels: [
                        '13 Nov 2017',
                        '14 Nov 2017',
                        '15 Nov 2017',
                        '16 Nov 2017',
                        '17 Nov 2017',
                        '20 Nov 2017',
                        '21 Nov 2017',
                        '22 Nov 2017',
                        '23 Nov 2017',
                        '24 Nov 2017',
                        '27 Nov 2017',
                        '28 Nov 2017',
                        '29 Nov 2017',
                        '30 Nov 2017',
                        '01 Dec 2017',
                        '04 Dec 2017',
                        '05 Dec 2017',
                        '06 Dec 2017',
                        '07 Dec 2017',
                        '08 Dec 2017',
                    ],
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            style: {
                                colors: '#FFFFFF' // 设置文字颜色，这里使用红色作为示例
                            }
                        }
                    },
                    yaxis: {
                        opposite: true,
                        labels: {
                            style: {
                                colors: '#FFFFFF' // 设置文字颜色，这里使用红色作为示例
                            }
                        }
                    },
                    legend: {
                        horizontalAlign: 'left',
                    },
                    toolbar: {
                        show: false,
                    }

                }}

                type="area"
                series={data}
                height={300}
            />
        </div> */}
        <Banner />

        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">總資產</p>
            <div className="flex justify-between items-end">
                <p className="text-xl font-bold text-white">$6,666,666,666.88</p>
                <p className="text-white text-sm"><span className="text-green1 mr-2">+ $66.8888 (0.30%)</span> 今日</p>
            </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                <p className="text-base font-bold text-white mb-4">當日年化報酬估算</p>
                <div className="flex justify-between items-end">
                    <p className="text-xl font-bold text-white">20 %</p>
                    <p className="text-white text-sm">APR</p>
                </div>
            </div>

            <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                <p className="text-base font-bold text-white mb-4">當日收益估算</p>
                <div className="flex justify-between items-end">
                    <p className="text-xl font-bold text-green1">+{separatorNumber('2111000.11')}</p>
                    <p className="text-white text-sm">USDT</p>
                </div>
            </div>

            <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                <p className="text-base font-bold text-white mb-4">總利息收入</p>
                <div className="flex justify-between items-end">
                    <p className="text-xl font-bold text-green1">+{separatorNumber('2111000.11')}</p>
                    <p className="text-white text-sm">USDT</p>
                </div>
            </div>

        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                <p className="text-base font-bold text-white mb-4">已借出資金</p>
                <div className="w-full max-w-[380px] ">
                    <Chart options={pie1State.options} series={pie1State.series} type="pie" width={'100%'} />
                </div>

            </div>

            <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
                <p className="text-base font-bold text-white mb-4">24hr 資金閒置率</p>
                <div className="w-full max-w-[380px]">
                    <Chart options={pie2State.options} series={pie2State.series} type="pie" width={'100%'} />
                </div>

            </div>
        </div>


        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">24hr 資金閒置率</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">幣種</th>
                            <th className="">使用率</th>
                            <th className="">資訊</th>

                            <th className="">平均利率</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-center text-white font-semibold ">
                                <td >
                                    <div className="relative flex gap-2 items-center mr-5 ">
                                        <img className=" w-5 object-contain" src={i.img} />
                                        <p>{i.title}</p>
                                    </div>
                                </td>
                                <td className=" font-normal" width="180">
                                    <div className="flex justify-end text-xs mb-1">
                                        <div>93.33%</div>
                                    </div>
                                    <LinearProgress className=" rounded-md"
                                        sx={{
                                            backgroundColor: '#51639A',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: '#50DA6D'
                                            }
                                        }} color="inherit" variant="determinate" value={93.33} />
                                    <div className="flex justify-between mt-1">
                                        <div>可用:</div>
                                        <div>33.33</div>
                                    </div>
                                </td>
                                <td className="font-normal">
                                    <div>今日收益: 12.65</div>
                                    <div>本日收益: 12.65</div>
                                </td>

                                <td className="">17%</td>



                            </tr>
                            )
                        }


                    </tbody>
                </table>
            </div>

        </div>

        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">可用資產</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">幣種</th>
                            <th className="">市場數據</th>
                            <th className="">交易量</th>

                            <th className="">可用餘額</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-center text-white font-semibold ">
                                <td >
                                    <div className="relative flex gap-2 items-center mr-5 ">
                                        <img className=" w-5 object-contain" src={i.img} />
                                        <p>{i.title}</p>
                                    </div>

                                </td>
                                <td className=" font-normal" width="180">
                                    <div className="flex justify-end text-xs mb-1">
                                        <div>63.33%</div>
                                    </div>
                                    <LinearProgress className=" rounded-md"
                                        sx={{
                                            backgroundColor: '#51639A',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: '#50DA6D'
                                            }
                                        }} color="inherit" variant="determinate" value={63.33} />
                                    <div className="flex justify-between mt-2">
                                        <div>
                                            <div className="text-xs">低</div>
                                            <div className="text-red1">2.53%</div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-right">高</div>
                                            <div className="text-green1">2.53%</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="font-normal">
                                    328,928,111 {i.title}
                                </td>

                                <td className="">0 {i.title}</td>



                            </tr>
                            )
                        }


                    </tbody>
                </table>
            </div>

        </div>


        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <img className="w-full object-contain rounded-lg" src={pic1Img} />

        </div>




    </div >
}

export default Home