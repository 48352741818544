
import { useDispatch } from 'react-redux';
import moment from 'moment'
import UserApi from '../services/UserApi';
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import Tether from '../assets/currency/Tether.png'
import USD from '../assets/currency/USD.png'
import EUR from '../assets/currency/EUR.png'
import JPY from '../assets/currency/JPY.png'

export const currencyList = [
  {
    img: Tether,
    title: "USDT"
  },
  {
    img: USD,
    title: "USD"
  },
  {
    img: EUR,
    title: "EUR"
  },
  {
    img: JPY,
    title: "JPY"
  }

]






export const useGlobalFunc = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const setLoading = async (status) => {
    if (status == true) {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: true }
      });
    }
    else {
      dispatch({
        type: "UPDATE_LOADING",
        payload: { loading: false }
      });
    }

  };

  const updateMe = async () => {
    if (localStorage.getItem('userToken')) {
      try {
        let res = await UserApi.me()
        console.log(res.data)
        
        dispatch({
          type: "UPDATE_USERINFO",
          payload: { userInfo: res.data.data.user }
        });
      }
      catch (errors) {
        console.log('removeToken')
        localStorage.removeItem("userToken")
        window.location.reload()
        //errorToast(errors?.response?.data?.message || errors.toString())
      }
    }
  };

  function successToast(message) {
    console.log(message)
    return toast.success(t(message))
    //toast.success(t(message))
  }

  function errorToast(message) {
    console.log(message)
    return toast.error(t(message))
    // toast.error(t(message))
  }
  function alertDialog(message) {
    console.log(message)
    dispatch({
      type: "UPDATE_ALERTDIALOG",
      payload: { alertDialog: { isOpen: true, content: message } }
    });
    return
    // toast.error(t(message))
  }


  return { updateMe, setLoading, successToast, errorToast, alertDialog };

};

export function time2date(date) {
  return moment(date).format("YYYY-MM-DD")
}
export function time2date2(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss")
}


export function checkEventStatus(start, end) {
  let toStart = moment(start).valueOf() - moment().valueOf()
  let toEnd = moment(end).valueOf() - moment().valueOf()

  if (toStart > 0) {
    return "即將開始"
    return `${Math.floor(toStart / 86400000)}天後開始`
  }
  else if (toEnd > 0) {
    return `最後${Math.floor(toEnd / 86400000)}天`
  }
  else {
    return `已完結`
  }
  //return moment(end).valueOf()

}


export function separatorNumber(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}



