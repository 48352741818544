

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";




import logoImg from '../../assets/logo.png'


import { currencyList, separatorNumber, useGlobalFunc } from "../../global/constants.js";



import Chart from 'react-apexcharts'
import Banner from "../../compontents/Banner";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const scrollContainerRef = useRef(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [scrollPosition, setScrollPosition] = useState(0)

    const [currTimeIndex, setCurrTimeIndex] = useState(0)

    const [dataList, setDataList] = useState([])


    function handleResize() {
        setWindowHeight(window.innerHeight)
    }


    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return <div className="relative  px-4 py-10 " >


        <Banner />


        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8 mb-4" >
            <p className="text-base font-bold text-white mb-4">各貨幣放款狀態</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">幣種</th>
                            <th className="">已借出</th>
                            <th className="">已借出單數</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-top text-white font-semibold ">
                                <td className=" flex gap-2 items-center mr-5">
                                    <img className="w-5 object-contain" src={i.img} />
                                    <p>{i.title}</p>

                                </td>
                                <td className="">123,000 {i.title}</td>
                                <td className="flex justify-start gap-2">
                                    <div>{(10 - index + 4) * 3}單
                                    </div>
                                    <svg className=" cursor-pointer " onClick={() => navigate(`/interest/` + i.title)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C16.9707 3 21 7.0293 21 12C21 16.9707 16.9707 21 12 21C7.0293 21 3 16.9707 3 12C3 7.0293 7.0293 3 12 3ZM10.4637 14.0637C10.2998 14.2334 10.209 14.4608 10.2111 14.6968C10.2131 14.9327 10.3078 15.1585 10.4747 15.3253C10.6415 15.4922 10.8673 15.5869 11.1032 15.5889C11.3392 15.591 11.5666 15.5002 11.7363 15.3363L14.4363 12.6363C14.605 12.4675 14.6998 12.2386 14.6998 12C14.6998 11.7614 14.605 11.5325 14.4363 11.3637L11.7363 8.6637C11.6533 8.57774 11.554 8.50918 11.4442 8.46201C11.3344 8.41484 11.2163 8.39001 11.0968 8.38897C10.9773 8.38794 10.8587 8.41071 10.7481 8.45596C10.6375 8.50121 10.537 8.56804 10.4525 8.65254C10.368 8.73705 10.3012 8.83753 10.256 8.94814C10.2107 9.05875 10.1879 9.17726 10.189 9.29676C10.19 9.41626 10.2148 9.53436 10.262 9.64416C10.3092 9.75397 10.3777 9.85328 10.4637 9.9363L12.5274 12L10.4637 14.0637Z" fill="#CCCCCC" />
                                    </svg>
                                </td>


                            </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>

        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
            <p className="text-base font-bold text-white mb-4">放貸中</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">幣種</th>
                            <th className="">已借出數量</th>
                            <th className="">利率</th>
                            <th className="">收益</th>
                            <th className="">到期</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-top text-white font-semibold ">
                                <td className="relative flex gap-2 items-center mr-5">
                                    <img className=" w-5 object-contain" src={i.img} />
                                    <p>{i.title}</p>

                                </td>
                                <td className="">123,000 {i.title}</td>
                                <td className="">15.66%</td>
                                <td className="">8,411.22 {i.title}</td>
                                <td className="">一天內</td>



                            </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>




    </div >
}

export default Home