

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";




import logoImg from '../../assets/logo.png'


import { currencyList, separatorNumber, useGlobalFunc } from "../../global/constants.js";



import Chart from 'react-apexcharts'
import Banner from "../../compontents/Banner";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const scrollContainerRef = useRef(null);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [scrollPosition, setScrollPosition] = useState(0)



    const [currentCurrencyTitle, setCurrentCurrencyTitle] = useState('')

    const handleScroll = () => {
        //console.log(windowHeight)
        const newScrollPosition = scrollContainerRef.current.scrollTop;
        console.log(newScrollPosition)
        setScrollPosition(newScrollPosition);

    };

    function isCurrentPage(page) {
        //console.log(page)
        //console.log(windowHeight * (page - 1), scrollPosition, windowHeight * (page + 2))
        if (scrollPosition > windowHeight * (page - 1) && scrollPosition < windowHeight * (page + 2)) {
            return true
        }
        else {
            return false
        }

    }

    function countOpacity(page) {
        if (scrollPosition > windowHeight * (page) - (windowHeight * 0.1) && scrollPosition < windowHeight * (page) + (windowHeight * 0.1)) {
            return 1
        }
        else {
            return 0
        }


    }

    function handleResize() {
        setWindowHeight(window.innerHeight)
    }


    useEffect(() => {
        let title = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setCurrentCurrencyTitle(title)
    }, []);

    return <div className="relative  px-4 py-10 " >
        <Banner/>
        <div className="bg-main2 rounded-md  w-full  p-4 md:p-8" >
            <p className="text-base font-bold text-white mb-4">{currentCurrencyTitle}</p>
            <div className="   whitespace-nowrap w-full  overflow-x-auto ">
                <table className="w-full text-sm" >
                    <thead className="">

                        <tr className="  text-white bg-main3 bg-opacity-40 font-semibold rounded-xl">
                            <th className="">幣種</th>
                            <th className="">已借出數量</th>
                            <th className="">利率</th>
                            <th className="">收益</th>
                            <th className="">到期</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            currencyList && currencyList.map((i, index) => <tr className="align-top text-white font-semibold ">
                                <td className=" flex gap-2 items-center mr-5">
                                    <img className="w-5 object-contain" src={i.img} />
                                    <p>{i.title}</p>

                                </td>
                                <td className="">123,000 {i.title}</td>
                                <td className="">15.66%</td>
                                <td className="">8,411.22 {i.title}</td>
                                <td className="">一天內</td>



                            </tr>
                            )
                        }

                    </tbody>
                </table>
            </div>

        </div>



    </div >
}

export default Home